// TODO: this is a hasty clone of the LxfCallout component,
// and the commonalities between them need to be refactored.

import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import useHover from '@hzdg/use-hover';
import {styled, Colors, ThemeProvider, theme, Fonts} from '@styles';
import {ResponsiveContainer} from '@components/layout';
import {SpeedbumpForwardLink, Link} from '@components/Link';
import Image, {fluidImageFragment} from '@components/Image';
import {LxfSpeedbump} from '@components/Link/Speedbumps';

const Container = styled(ResponsiveContainer).withConfig({
  componentId: 'lxfCalloutContainer'
})`
  padding: 2.5em 2em 4.5em;
  margin: 0 auto;
  width: 100%;
  max-width: ${theme.maxWidth};
`;

const Description = styled.div.withConfig({
  componentId: 'lxfCalloutDescription'
})`
  ${Fonts.ProximaNova.variants.ExtraBold};
  font-size: ${34 / 16}em;
  line-height: ${40 / 34};
  letter-spacing: ${-1.09 / 34}em;

  .wide & {
    font-size: ${40 / 16}em;
    line-height: ${49 / 40};
    letter-spacing: ${-1.09 / 40}em;
  }
`;

const DescriptionContainer = styled.div.withConfig({
  componentId: 'lxfCalloutDescriptionContainer'
})`
  max-width: unset;
  grid-area: description;
  position: relative;
  z-index: 1;

  .wide & {
    max-width: 52%;
  }
`;

const Label = styled.div.withConfig({
  componentId: 'lxfCalloutLabel'
})`
  font-weight: 600;
`;

const LinkContainer = styled.div.withConfig({
  componentId: 'lxfLinkContainer'
})`
  margin-top: 1em;
`;

const ImageContainer = styled.div.withConfig({
  componentId: 'lxfImageContainer'
})`
  transform: unset;
  margin-top: 2em;

  .wide & {
    transform: translate(1em, -7em);
  }
`;

const IMG_QUERY = graphql`
  query LxfImage($quality: Int) {
    image: file(relativePath: {eq: "lxf-pages.png"}) {
      ...fluidImageFragment
    }
  }
`;

const LxfCallout = (): JSX.Element => {
  const data = useStaticQuery(IMG_QUERY);
  const [isHover, hoverProps] = useHover();
  return (
    <ThemeProvider
      theme={{
        maxWidth: '80%',
        ctaIconFg: Colors.Blue,
        ctaIconBg: Colors.Blue,
        ctaHoverFg: Colors.White,
        ctaHoverBg: Colors.Blue,
      }}
    >
      <Container>
        <DescriptionContainer>
          <Description>
            The LXF is not just a document, it’s part of our DNA. And it
            codifies our shared, learner-centered approach to creating
            transformative education.
          </Description>
          <LinkContainer>
            <SpeedbumpForwardLink
              isHover={isHover}
              filledOnHover={true}
              href="/static/2U_LearningExperienceFramework.pdf"
              Speedbump={LxfSpeedbump}
              {...hoverProps}
            >
              <Label>Download the LXF</Label>
            </SpeedbumpForwardLink>
          </LinkContainer>
        </DescriptionContainer>
        <ImageContainer>
          <Image childImageSharp={data.image.childImageSharp} />
        </ImageContainer>
      </Container>
    </ThemeProvider>
  );
};

export default LxfCallout;
