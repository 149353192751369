import React from 'react';
import {styled, Colors, theme, Fonts, Scales} from '@styles';
import {Headline, Paragraph} from '@components/typography';
import {ResponsiveContainer} from '@components/layout';
import {SpeedbumpCompnentProps, Link} from '@components/Link';
import {FocusScope} from '@hzdg/focus-scope';
import useScrollBlocker from '@util/useScrollBlocker';

const Sb = {
  Dialog: styled(ResponsiveContainer).withConfig({
    componentId: 'speedbumpsDialog'
  })`
    ${Fonts.ProximaNova};
    font-size: ${Scales.Small}em;
    background-color: ${theme.bg};
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    &.wide {
      font-size: 1em;
      max-width: 800px;
      margin: 3em;
    }
  `,
  DialogBody: styled.div.withConfig({
    componentId: 'speedbumpsDialogBody'
  })`
    flex: 1 1;
    overflow-y: auto;
    padding: 1em;
  `,
  DialogButtonRow: styled.div.withConfig({
    componentId: 'speedbumpsDialogButtonRow'
  })`
    flex: 0 0;
    padding: 1em;
    margin-top: 2em;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `,
  DialogButton: styled.button.withConfig({
    componentId: 'speedbumpsDialogButton'
  })<{primary?: boolean}>`
    font-size: 1em;
    background-color: ${Colors.White};
    border: 2px solid
      ${props => (props.primary ? Colors.Blue : Colors.CharcoalLight)};
    border-radius: 5px;
    color: ${props => (props.primary ? Colors.Blue : Colors.CharcoalLight)};
    padding: 0.4em 0.6em;
    font-weight: bold;
    margin-left: 0.4em;
    transition: background 150ms ease-in-out, color 150ms ease-in-out;
    &:hover,
    &:focus {
      background-color: ${props =>
        props.primary ? Colors.Blue : Colors.CharcoalLight};
      color: ${Colors.White};
    }
    &:active {
      box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    }
  `,
};

export const LxfSpeedbump: SpeedbumpCompnentProps = ({done}) => {
  useScrollBlocker();

  return (
    // eslint-disable-next-line jsx-a11y/no-autofocus
    <FocusScope as={Sb.Dialog} autoFocus={true} trap={true}>
      <Sb.DialogBody>
        <Headline>Attention</Headline>
        <Paragraph>
          By clicking “Accept”, you agree to be bound by the terms of this
          license. If you do not agree, no rights are granted to you under this
          agreement.
        </Paragraph>
        <Paragraph>
          2U grants you the right to use, download, and print copies of the
          “Learning Experience Framework” white paper (the “Work”) subject to
          the following express conditions—1) you may use the Work solely for
          your internal business or personal use; 2) you may only distribute the
          Work to your employees, independent contractors, and agents for their
          use related to their employment by you, and who have agreed to be
          bound by the terms of this license; 3) you may not modify, edit,
          amend, or otherwise create any derivative works of the Work; 4) you
          shall not remove, obscure, or modify the copyright notices included on
          the Work, and any copies of the Work printed or distributed by you
          shall include the original notices; and 5) any public reference by you
          to the Work shall include proper attribution identifying 2U, Inc as
          the author of the Work and include the following URL{' '}
          <Link href="https://2u.com/approach/learning-design">
            https://2u.com/approach/learning-design.
          </Link>
        </Paragraph>
        <Paragraph>
          Your failure to abide by any one of these conditions shall invalidate
          your license to the Work. You may not further disclose, disseminate,
          distribute, reproduce or retransmit, including but not limited to for
          profit, to any person, firm, or entity without 2U’s written
          permission.
        </Paragraph>
      </Sb.DialogBody>
      <Sb.DialogButtonRow>
        <Sb.DialogButton
          onClick={() => {
            done(false);
          }}
        >
          Cancel
        </Sb.DialogButton>
        <Sb.DialogButton
          primary
          onClick={() => {
            done(true);
          }}
        >
          Accept
        </Sb.DialogButton>
      </Sb.DialogButtonRow>
    </FocusScope>
  );
};
